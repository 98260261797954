import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './pages/public/forget-password/forget-password.component';
import { PublicLayoutComponent } from './pages/public/layout/public/public.component';
import { LoginComponent } from './pages/public/login/login.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { ResetPasswordComponent } from './pages/public/reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/main/dashboard/dashboard.component';
import { MainComponent } from './pages/main/layout/main/main.component';
import { AdminOrganizationsComponent } from './pages/main/admin-organizations/organizations.component';
import { AdminUsersComponent } from './pages/main/admin-users/users.component';
import { ValidateEmailComponent } from './pages/public/validate-email/validate-email.component';
import { AdminOrganizationDetailsComponent } from './pages/main/admin-organization-details/organization-details.component';
import { AdminUserDetailsComponent } from './pages/main/admin-user-details/user-details.component';
import { AdminActivityComponent } from './pages/main/admin-activity/activity.component';
import { ProfileComponent } from './pages/main/my-profile/profile.component';
import { AdminAuthGuard } from './guards/admin.guard';
import { AboutComponent } from './pages/main/about/about.component';
import { NewInquiriesComponent } from './pages/main/new-inquiries/new-inquiries.component';
import { MyOffersComponent } from './pages/main/my-offers/my-offers.component';
import { MyOrdersComponent } from './pages/main/my-orders/my-orders.component';
import { MyPaymentsComponent } from './pages/main/my-payments/my-payments.component';
import { MyRatingsComponent } from './pages/main/my-ratings/my-ratings.component';
import { InquiryStep1Component } from './pages/public/inquiry/raw/step1/step1.component';
import { InquiryStep4Component } from './pages/public/inquiry/raw/step4/step4.component';
import { InquiryStep3Component } from './pages/public/inquiry/raw/step3/step3.component';
import { InquiryStep2Component } from './pages/public/inquiry/raw/step2/step2.component';
import { InquirySuccessComponent } from './pages/public/inquiry/raw/step_success/step_success.component';
import { AdminInquiriesComponent } from './pages/main/admin-inquiries/admin-inquiries.component';
import { InquiryDetailsComponent } from './pages/main/inquiry-details/inquiry-details.component';
import { OfferDetailsComponent } from './pages/main/offer-details/offer-details.component';
import { AdminOffersComponent } from './pages/main/admin-offers/admin-offers.component';
import { InquiryStep6Component } from './pages/public/inquiry/raw/step6/step6.component';
import { InquiryStep5Component } from './pages/public/inquiry/raw/step5/step5.component';
import { OrderDetailsComponent } from './pages/main/order-details/order-details.component';
import { AdminOrderDetailsComponent } from './pages/main/admin-order-details/admin-order-details.component';
import { AdminOrdersListComponent } from './pages/main/admin-orders/admin-orders.component';
import { RateOrderComponent } from './pages/public/rate-order/rate-order.component';
import { AdminInquiryDetailsComponent } from './pages/main/admin-inquiry-details/admin-inquiry-details.component';
import { AdminRatingsListComponent } from './pages/main/admin-ratings/admin-ratings.component';
import { PublicOrderDetailsComponent } from './pages/public/order-details/order-details.component';
import { AdminEditInquiryInventoryComponent } from './pages/main/admin-edit-inquiry-inventory/admin-edit-inquiry-inventory.component';
import { CompanyRatingsComponent } from './pages/public/company-ratings/company-ratings.component';
import { ChooseOfferComponent } from './pages/public/choose-offer/choose-offer.component';
import { OrganizationDetailsComponent } from './pages/main/my-organization-details/organization-details.component';
import { InquiryV2Component } from './pages/public/inquiry/v2/inquiry.component';
import { InquiryNextStepsComponent } from './pages/public/inquiry/raw/next-steps/next-steps.component';
import { InquiryExtendComponent } from './pages/public/inquiry/raw/extend/extend.component';
import { AdminOffersDetailsComponent } from './pages/main/admin-offers-details/admin-offers-details.component';
import { BlankComponent } from './pages/public/blank/blank.component';

const routes: Routes = [

  // {
  //   path: 'main',
  //   component: MainComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: 'admin',
  //       canActivate: [AdminAuthGuard],
  //       children: [
  //         {
  //           path: 'organizations/:organizationUUID',
  //           component: AdminOrganizationDetailsComponent,
  //         },
  //         {
  //           path: 'organizations',
  //           component: AdminOrganizationsComponent,
  //         },
  //         {
  //           path: 'activity',
  //           component: AdminActivityComponent
  //         },

  //         {
  //           path: 'inquiries',
  //           component: AdminInquiriesComponent,
  //         },
  //         {
  //           path: 'inquiries/:inquiryUUID',
  //           children: [
  //             {
  //               path: '',
  //               component: AdminInquiryDetailsComponent,
  //             },{
  //               path: 'edit-inventory',
  //               component: AdminEditInquiryInventoryComponent,
  //             }
  //           ]
  //         },

  //         {
  //           path: 'offers',
  //           component: AdminOffersComponent,
  //         },
  //         {
  //           path: 'offers/:offerUUID',
  //           component: AdminOffersDetailsComponent,
  //         },

  //         {
  //           path: 'orders',
  //           component: AdminOrdersListComponent,
  //         },
  //         {
  //           path: 'orders/:orderUUID',
  //           component: AdminOrderDetailsComponent,
  //         },

  //         {
  //           path: 'users',
  //           component: AdminUsersComponent
  //         },
  //         {
  //           path: 'users/:userUUID',
  //           component: AdminUserDetailsComponent
  //         },
  //         {
  //           path: 'ratings',
  //           component: AdminRatingsListComponent
  //         },
  //       ]
  //     },
  //     {
  //       path: 'dashboard',
  //       component: DashboardComponent
  //     },
  //     {
  //       path: 'profile',
  //       component: ProfileComponent
  //     },

  //     {
  //       path: 'organization',
  //       component: OrganizationDetailsComponent
  //     },
  //     {
  //       path: 'about',
  //       component: AboutComponent
  //     },

  //     {
  //       path: 'new-inquiries',
  //       component: NewInquiriesComponent
  //     },
  //     {
  //       path: 'inquiries/:inquiryUUID',
  //       component: InquiryDetailsComponent,
  //       data: { isAdminView: false }
  //     },
  //     {
  //       path: 'my-offers',
  //       component: MyOffersComponent
  //     },

  //     {
  //       path: 'my-orders',
  //       component: MyOrdersComponent
  //     },
  //     {
  //       path: 'my-orders/:orderUUID',
  //       component: OrderDetailsComponent
  //     },
  //     {
  //       path: 'my-payments',
  //       component: MyPaymentsComponent
  //     },

  //     {
  //       path: 'my-ratings',
  //       component: MyRatingsComponent
  //     },

  //     {
  //       path: '**',
  //       redirectTo: 'dashboard'
  //     }
  //   ]
  // },
  // {
  //   path: 'inquiry/v2',
  //   component: InquiryV2Component
  // },
  // {
  //   path: 'inquiry',
  //   children: [
  //     {
  //       path: '1',
  //       component: InquiryStep1Component
  //     },
  //     {
  //       path: '2',
  //       component: InquiryStep2Component
  //     },

  //     {
  //       path: '3',
  //       component: InquiryStep3Component
  //     },

  //     {
  //       path: '4',
  //       component: InquiryStep4Component
  //     },
  //     {
  //       path: '5',
  //       component: InquiryStep5Component
  //     },
  //     {
  //       path: '6',
  //       component: InquiryStep6Component
  //     },
  //     {
  //       path: 'next-steps',
  //       component: InquiryNextStepsComponent
  //     },
  //     {
  //       path: 'extend',
  //       component: InquiryExtendComponent
  //     },
  //     {
  //       path: 'success',
  //       component: InquirySuccessComponent
  //     },


  //     {
  //       path: '**',
  //       redirectTo: '1'
  //     }
  //   ]
  // },
  // {
  //   path: 'rate-order',
  //   component: RateOrderComponent
  // },
  // {
  //   path: 'choose-offer',
  //   component: ChooseOfferComponent
  // },
  // {
  //   path: 'company-rating',
  //   component: CompanyRatingsComponent
  // },

  // // "http://localhost:4200/order-details?tk=ffcca70d-608c-4bad-9314-fe2f947f961b"
  // {
  //   path: 'order-details',
  //   component: PublicOrderDetailsComponent
  // },
  // {
  //   path: '',
  //   component: PublicLayoutComponent,
  //   children: [
  //     {
  //       path: 'login',
  //       component: LoginComponent
  //     },
  //     {
  //       path: 'validate-email',
  //       component: ValidateEmailComponent
  //     },
  //     {
  //       path: 'register',
  //       component: RegisterComponent
  //     },
  //     {
  //       path: 'forgot-password',
  //       component: ForgetPasswordComponent
  //     },
  //     {
  //       path: 'reset-password',
  //       component: ResetPasswordComponent
  //     },
  //     {
  //       path: '**',
  //       redirectTo: 'login'
  //     }
  //   ]
  // },
  {
    path: "blank", component: BlankComponent
  },
  {
    path: '**', redirectTo: 'blank',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
