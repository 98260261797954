import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { APP_NAME, APP_VERSION, AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularFireModule } from '@angular/fire/compat';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';

import { MatCardModule } from '@angular/material/card'
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';

import * as Sentry from "@sentry/angular-ivy";

import { versions } from 'projects/versions';

import { AppComponent } from './app.component';
import { environment } from './environments/environment.prod';
import { LoginComponent } from './pages/public/login/login.component';
import { ResetPasswordComponent } from './pages/public/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './pages/public/forget-password/forget-password.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { PublicFooterComponent } from './pages/public/layout/footer/footer.component';
import { PublicHeaderComponent } from './pages/public/layout/header/header.component';
import { PublicLayoutComponent } from './pages/public/layout/public/public.component';
import { ToastrModule } from 'ngx-toastr';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { CompagnoAvatarModule } from './modules/avatar/avatar.module';
import { GenderPipe, YesNoPipe } from './pipes/yes-no.pipe';
import { Router } from '@angular/router';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token/refresh-token.interceptor';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { AppVersionInterceptor } from './interceptors/app-version/app-version.interceptor';
import { UserCardComponent } from './components/user-card/user-card.component';
import { EnumIntToDescriptionPipe } from './services/enum-int-string.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HumanDatePipe } from './pipes/human-date.pipe';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { DashboardComponent } from './pages/main/dashboard/dashboard.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { OrgOverviewComponent } from './components/org-overview/org-overview.component';
import { UserEditorComponent, UserPasswordEditorComponent } from './components/user-editor/user-editor.component';
import { MainComponent } from './pages/main/layout/main/main.component';
import { HeaderComponent } from './pages/main/layout/header/header.component';
import { MenuItemComponent } from './pages/main/layout/menu-item/menu-item.component';
import { OrganizationSelectorComponent } from './components/organization-selector/organization-selector.component';
import { AdminOrganizationsComponent } from './pages/main/admin-organizations/organizations.component';
import { AdminUsersComponent } from './pages/main/admin-users/users.component';
import { ValidateEmailComponent } from './pages/public/validate-email/validate-email.component';
import { CaptchaModule } from './modules/captcha/captcha.module';
import { UserEditorFormComponent } from './components/user-editor-form/user-editor-form.component';

import { AdminOrganizationDetailsComponent } from './pages/main/admin-organization-details/organization-details.component';
import { AdminUserDetailsComponent } from './pages/main/admin-user-details/user-details.component';
import { AdminActivityComponent } from './pages/main/admin-activity/activity.component';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProfileComponent } from './pages/main/my-profile/profile.component';
import { AboutComponent } from './pages/main/about/about.component';
import { StatisticsCardComponent } from './components/statistics-card/statistics-card.component';
import { NextWorksComponent } from './components/next-works-card/next-works-card.component';
import { RatingCardComponent } from './components/rating-card/rating-card.component';
import { SideBarBottomComponent } from './pages/main/layout/sidebar-bottom.component';
import { NewInquiriesComponent } from './pages/main/new-inquiries/new-inquiries.component';
import { MyOrdersComponent } from './pages/main/my-orders/my-orders.component';
import { MyOffersComponent } from './pages/main/my-offers/my-offers.component';
import { MyPaymentsComponent } from './pages/main/my-payments/my-payments.component';
import { MyRatingsComponent } from './pages/main/my-ratings/my-ratings.component';
import { InquiryComponent } from './pages/public/inquiry/inquiry.component';
import { InquiryStep1Component } from './pages/public/inquiry/raw/step1/step1.component';
import { InquiryStep2Component } from './pages/public/inquiry/raw/step2/step2.component';
import { InquiryStep3Component } from './pages/public/inquiry/raw/step3/step3.component';
import { InquiryStep4Component } from './pages/public/inquiry/raw/step4/step4.component';
import { SelectorComponent } from './pages/public/inquiry/raw/selector/selector.component';
import { ButtonSelectorComponent } from './pages/public/inquiry/raw/button-selector/button-selector.component';
import { InquirySuccessComponent } from './pages/public/inquiry/raw/step_success/step_success.component';
import { AdminInquiriesComponent } from './pages/main/admin-inquiries/admin-inquiries.component';
import { ElevatorTypePipe, FloorsTypePipe, GenericValueDisplayPipe, InquiryStatusPipe, NumberOfRoomsPipe, OrderPaymentTypePipe, OrderStatePipe, PropertyTypePipe, ServiceTypePipe, SizeSquareTypePipe } from './pipes/inquiry-pipe.pipe';
import { InquiryCardComponent } from './pages/main/inquiry-card/inquiry-card.component';
import { OfferDetailsComponent } from './pages/main/offer-details/offer-details.component';
import { AdminOffersComponent } from './pages/main/admin-offers/admin-offers.component';
import { InquiryStep5Component } from './pages/public/inquiry/raw/step5/step5.component';
import { InquiryStep6Component } from './pages/public/inquiry/raw/step6/step6.component';
import { RoomComponent } from './pages/public/inquiry/raw/step5/room.component';
import { FurnitureComponent } from './pages/public/inquiry/raw/step5/furniture.component';
import { InquiryDetailsComponent } from './pages/main/inquiry-details/inquiry-details.component';
import { PricePipe, ThousandsSeparatorPipe } from './pipes/price.pipe';
import { OrderDetailsComponent } from './pages/main/order-details/order-details.component';
import { BidCardModalComponent } from './components/bid-card-modal/bid-card-modal.component';
import { AdminOrderDetailsComponent } from './pages/main/admin-order-details/admin-order-details.component';
import { AdminOrdersListComponent } from './pages/main/admin-orders/admin-orders.component';
import { RateOrderComponent } from './pages/public/rate-order/rate-order.component';
import { ChooseOfferComponent, CompanySelectionModalComponent } from './pages/public/choose-offer/choose-offer.component';
import { InquiryDetailsCardComponent } from './pages/main/inquiry-details-card/inquiry-details-card.component';
import { AdminInquiryDetailsComponent } from './pages/main/admin-inquiry-details/admin-inquiry-details.component';
import { AdminRatingsListComponent } from './pages/main/admin-ratings/admin-ratings.component';
import { CommentsCardComponent } from './components/comments-card/comments-card.component';
import { InquiryHeaderComponent } from './pages/public/inquiry/raw/inquiry-header/inquiry-header.component';
import { PublicOrderDetailsComponent } from './pages/public/order-details/order-details.component';
import { AdminEditInquiryInventoryComponent } from './pages/main/admin-edit-inquiry-inventory/admin-edit-inquiry-inventory.component';
import { CompanyRatingsComponent } from './pages/public/company-ratings/company-ratings.component';
import { OrganizationDetailsComponent } from './pages/main/my-organization-details/organization-details.component';
import { InquiryV2Component } from './pages/public/inquiry/v2/inquiry.component';
import { InquiryNextStepsComponent } from './pages/public/inquiry/raw/next-steps/next-steps.component';
import { InquiryExtendComponent } from './pages/public/inquiry/raw/extend/extend.component';
import { OfferDetailsModalComponent } from './components/offer-details-modal/offer-details-modal.component';
import { CompanyDetailsModalComponent } from './components/company-details-modal/company-details-modal.component';
import { AdminOffersDetailsComponent } from './pages/main/admin-offers-details/admin-offers-details.component';
import { BlankComponent } from './pages/public/blank/blank.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const pipes = [
  YesNoPipe,
  PropertyTypePipe,
  EnumIntToDescriptionPipe,
  HumanDatePipe,
  InquiryStatusPipe,
  PricePipe,
  ServiceTypePipe,
  FloorsTypePipe,
  SizeSquareTypePipe,
  ElevatorTypePipe,
  GenericValueDisplayPipe,
  ThousandsSeparatorPipe,
  OrderStatePipe,
  GenderPipe,
  OrderPaymentTypePipe,
  NumberOfRoomsPipe
]

const mainComponents = [
  MainComponent,
  HeaderComponent,
  MenuItemComponent,
]

const adminPages = [
  AdminOrganizationsComponent,
  AdminUsersComponent,
  AdminOrganizationDetailsComponent,
  AdminUserDetailsComponent,
  AdminActivityComponent,
]

const publicPages = [
  LoginComponent,
  ResetPasswordComponent,
  ForgetPasswordComponent,
  RegisterComponent,
  PublicFooterComponent,
  PublicHeaderComponent,
  PublicLayoutComponent,
  // TODO
  ValidateEmailComponent,
  ChooseOfferComponent,
]


@NgModule({
  declarations: [
    AppComponent,
    ...pipes,
    ...mainComponents,
    ...adminPages,
    ...publicPages,
    CommentsCardComponent,
    AdminOffersDetailsComponent,
    AdminEditInquiryInventoryComponent,
    BidCardModalComponent,
    InquiryDetailsCardComponent,
    ProfileComponent,
    InquiryV2Component,
    DashboardComponent,
    AboutComponent,
    OrganizationSelectorComponent,
    UserEditorComponent,
    UserPasswordEditorComponent,
    InquirySuccessComponent,
    OrganizationDetailsComponent,
    UserCardComponent,
    PublicOrderDetailsComponent,
    CompanyRatingsComponent,
    StatisticsCardComponent,
    RatingCardComponent,
    InquiryNextStepsComponent,
    InquiryExtendComponent,
    NextWorksComponent,
    SideBarBottomComponent,
    UserEditorFormComponent,
    UserSelectorComponent,
    OrgOverviewComponent,
    MyOrdersComponent,
    MyOffersComponent,
    MyPaymentsComponent,
    MyRatingsComponent,
    SelectorComponent,
    ButtonSelectorComponent,
    NewInquiriesComponent,
    InquiryComponent,
    InquiryCardComponent,
    InquiryStep1Component,
    InquiryStep2Component,
    InquiryStep3Component,
    InquiryStep4Component,
    InquiryStep5Component,
    InquiryStep6Component,
    OfferDetailsModalComponent,
    CompanyDetailsModalComponent,
    CompanySelectionModalComponent,
    RoomComponent,
    FurnitureComponent,
    InquiryDetailsComponent,
    AdminInquiriesComponent,
    AdminOffersComponent,
    OfferDetailsComponent,
    OrderDetailsComponent,
    RateOrderComponent,
    BlankComponent,
    AdminOrdersListComponent,
    AdminOrderDetailsComponent,
    AdminInquiryDetailsComponent,
    AdminRatingsListComponent,
    InquiryHeaderComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    DragDropModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    MatMenuModule,
    MatToolbarModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    CaptchaModule,
    AppRoutingModule,
    CompagnoAvatarModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    MatSlideToggleModule,
    MatNativeDatetimeModule,
    MatDatetimepickerModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:10000',
    // })
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true
    },
    { provide: APP_NAME, useValue: 'webapp' },
    { provide: APP_VERSION, useValue: versions.hash },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule { }
